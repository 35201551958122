<footer>

    <section id="delivery-sect" *ngIf="orgnizationData && orgnizationData.organizationName">
        <div class="container">
            <h6>
                <b>Why Shop From {{orgnizationData.organizationName}}?</b>
            </h6>
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <div class="card-block">
                        <div>
                            <img src="assets/img/Delivery-Truck.webp" alt="Logo">
                        </div>
                        <div class="cartInfo">
                            <div class="cartTitle">On Time Delivery</div>
                            <div class="subMessage">Providing delivery on 2-5 Hours.</div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <div class="card-block">
                        <div>
                            <img src="assets/img/Basket.webp" alt="Logo">
                        </div>
                        <div class="cartInfo">
                            <div class="cartTitle">Wide Assortment</div>
                            <div class="subMessage">Give you a great quantity, Organic, Fresh, Tasty, Healthy products.
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <div class="card-block">
                        <div>
                            <img src="assets/img/Tag.webp" alt="Logo">
                        </div>
                        <div class="cartInfo">
                            <div class="cartTitle">Fresh Products</div>
                            <div class="subMessage" *ngIf="orgnizationData && orgnizationData.organizationName">
                                {{orgnizationData.organizationName}} give you a great quantity, Organic, Fresh, Tasty,
                                Healthy
                                products.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <hr>
    <div class="container footer-row">
        <div class="row">
            <div class="col-md-3 footer-title" *ngIf="orgnizationData">
                <h3 class="themeColor">
                    <b
                        *ngIf="orgnizationData && orgnizationData.organizationName">{{orgnizationData.organizationName}}</b>
                </h3>
                <ul *ngIf="orgnizationData">
                    <li>
                        <img src="assets/img/Mobile.png" alt="Icon" width="100%" height="auto" class="footer-icon">
                        <a href="tel:+{{orgnizationData.mobile}}"> +91 {{orgnizationData.mobile}}</a>
                        <br>
                    </li>
                    <li>
                        <img src="assets/img/Email.png" alt="Icon" width="100%" height="auto" class="footer-icon">
                        <a href="mailto: {{orgnizationData.email}}"> {{orgnizationData.email}}</a>
                        <br>
                    </li>
                    <li>
                        <img src="assets/img/Website.png" alt="Icon" width="100%" height="auto" class="footer-icon">
                        <a href="{{orgnizationData.website}}"> {{orgnizationData.website}}</a>
                    </li>
                    <li *ngIf="orgnizationData.adressList && orgnizationData.adressList.length>0">
                        <img src="assets/img/map.png" alt="Icon" width="100%" height="auto" class="footer-icon">
                        <a> {{orgnizationAddr}}</a>
                    </li>
                    <div class="d-flex">
                        <a href="https://apnagrahak.com/" target="_blank">
                        <img class="playstore-icon" src="assets/img/Powered By AG1.png" alt="Logo"></a>
                    </div>
                    
                </ul>
            </div>
            <div class="col-md-2 footer-title">
                <h2>OUR STORES</h2>
                <ul routerLink="/web/store_location" class="cursorPointer">
                    <li *ngFor="let store of storesList">
                        <a>{{store.wareHouseName}}</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-2 footer-title">
                <h2>CATEGORIES</h2>
                <ul>
                    <li class="cursorPointer" *ngFor="let category of categoryList | slice:0:7;let last=last"
                        routerLink="/web/ag/products"
                        [queryParams]="{category:category.name,category_uid:category.categoryUid,onPage: true }"
                        (click)="scrollTop()">
                        <a>{{category.name}}</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-2 footer-title">
                <h2>ABOUT US</h2>
                <ul class="cursorPointer">
                    <li routerLink="/web/store_location">
                        <a>Store Locator</a>
                    </li>
                    <li  *ngIf="footerData.isShowAboutUs" (click)="goToPrivacyPolicy('About_Us')">
                        <a>About Us</a>
                    </li>
                    <li *ngIf="footerData.isShowPrivacyPolicy" (click)="goToPrivacyPolicy('Privacy_Policy')">
                        <a>Privacy Policy</a>
                    </li>
                    <li *ngIf="footerData.isShowTermsConditions" (click)="goToPrivacyPolicy('Terms_and_Conditions')">
                        <a>Terms and Conditions</a>
                    </li>
                    <li *ngIf="footerData.isShowReturnPolicy" (click)="goToPrivacyPolicy('Return_Policy')">
                        <a>Refund and Return Policy</a>
                    </li>
                    <div class="Social-icons">
                        <a *ngIf="footerData && footerData.fbLink" href="{{footerData.fbLink}}">
                            <i class="fa fa-facebook"></i>
                        </a>
                        <a *ngIf="footerData && footerData.instagramLink" href="{{footerData.instagramLink}}">
                            <i class="fa fa-instagram"></i>
                        </a>
                    </div>
                    <div class="d-flex">
                        <a *ngIf="footerData && footerData.playStoreLink" href="{{footerData.playStoreLink}}">
                            <img class="playstore-icon" src="assets/img/GooglePlay.png" alt="Logo">
                        </a>
                        <a *ngIf="footerData && footerData.appStoreLink" href="{{footerData.appStoreLink}}">
                            <img class="playstore-icon" src="assets/img/AppStore.png" alt="Logo">
                        </a>
                    </div>
                </ul>
            </div>
            <div class="col-md-3 footer-title download-btn">
                <!-- <h2>Download App</h2>
            <img src="assets/img/GooglePlay.png" alt="Banks" width="100%" height="auto">
            <img src="assets/img/AppStore.png" alt="Banks" width="100%" height="auto"> -->
            </div>
        </div>

    </div>
    <section id="footer-copyright">
        <div class="container">
            <div class="row">
                <div class="col-md-8 copy-right" *ngIf="orgnizationData && orgnizationData.organizationName">
                    <!-- <p>Copyright©2023. All rights reserved @{{orgnizationData.organizationName}}</p> -->
                    <p>Copyright © 2023 <a href="https://apnagrahak.com/" target="_blank">Apna Grahak.</a> All Rights Reserved.</p>
                </div>
                <!-- <div class="col-md-4 copy-top">
                    <img src="assets/img/Payments.webp" alt="Banks" width="100%" height="auto">
                  
                </div> -->
            </div>
        </div>
    </section>
</footer>