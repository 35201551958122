<section id="top-header" class="desktopView" *ngIf="orgnizationData">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 top-header-title">
        <div id="coponSlider">
          <owl-carousel-o [options]="advertisingSlider">
            <ng-container *ngFor="let item of couponList">
              <ng-template carouselSlide>
                <p (click)="copyToClipboard(item)">{{item.discountName}} | Code: {{item.discountCode}} <span
                    class="copyCoupon">Copy</span></p>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
      <div class="col-md-6 top-header-login">
        <span *ngIf="warehouseData && orgnizationData && orgnizationData.warehouseListCount>1" (click)="openStore()">
          <img *ngIf="warehouseData.wareHouseName" src="assets/img/shop.png" class="w-18"
            alt="Logo">{{warehouseData.wareHouseName}}</span>&nbsp;
        <span *ngIf="!user || !user.userId" data-toggle="modal" data-target="#signInModal"
          (click)="openSignInUP('signInOptions')"> <img src="assets/img/Sign Up.png" alt="Logo"> Sign In</span>&nbsp;
        <span *ngIf="!user || !user.userId" data-toggle="modal" data-target="#signInModal"
          (click)="openSignInUP('createAccount')">
          <img src="assets/img/Profile.png" alt="Logo"> Register</span>
        <span *ngIf="user && user.userId" (click)="signOut()"> <img src="assets/img/Profile.png" alt="Logo">Sign
          Out</span>
        <!-- <span *ngIf="showButton" (click)="addToHomeScreen()">
          <img src="assets/img/download.png">Download App
        </span> -->
      </div>
    </div>
  </div>
</section>
<header id="header-main" class="desktopView" *ngIf="orgnizationData">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 logo cursorPointer" routerLink="">
        <!-- <div width="100%" height="auto" *ngIf="orgnizationData && orgnizationData.organizationName">
          {{orgnizationData.organizationName}}
        </div> -->

        <!-- <img src="assets/img/Logo.png" alt="Logo" > -->
        <!-- Dynamic Logo -->
        <div class="d-flex"  *ngIf="orgnizationData && orgnizationData.isShowLogoWithNameOnWebsite">
          <img class="review-image2" *ngIf="orgnizationData.websiteLogoFilePath"
          src="https://ik.imagekit.io/fjimgkit//tr:w-250,h-150,cm-pad_resize/{{orgnizationData.websiteLogoFilePath}}"
          loading="lazy" onError="this.src='assets/img/errorLogo.png'">
          <div class="orgName1">{{orgnizationData.organizationName}}</div>
        </div>
        <div class="d-flex"  *ngIf="orgnizationData && !orgnizationData.isShowLogoWithNameOnWebsite && !orgnizationData.isShowLogoOnWebsite">
          <div class="orgName1">{{orgnizationData.organizationName}}</div>
        </div>
        <div class="d-flex"  *ngIf="orgnizationData && orgnizationData.isShowLogoOnWebsite">
          <img class="review-image3" *ngIf="orgnizationData.websiteLogoFilePath"
          src="https://ik.imagekit.io/fjimgkit//tr:w-250,h-150,cm-pad_resize/{{orgnizationData.websiteLogoFilePath}}"
          loading="lazy" onError="this.src='assets/img/errorLogo.png'">
          <div *ngIf="!orgnizationData.websiteLogoFilePath" class="orgName1">{{orgnizationData.organizationName}}</div>
        </div>

      </div>
      <div class="col-md-5 search-btn">
        <div class="nav-item dropdown searchBarDropdown">
          <form class="example">
            <input type="text" autocomplete="off" [(ngModel)]="searchProduct" class="nav-link dropdown-toggle"
              id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              name="search2" (change)="redirectToSearch(searchProduct)" placeholder="What you want to buy....">
            <button type="button" routerLink="/web/ag/products" [queryParams]="{tag:searchProduct}"><span><img
                  src="assets/img/Search.png" alt="Search" class="search-icon">
                SEARCH</span></button>
          </form>
          <div class="dropdown-menu searchDropdown" *ngIf="searchProduct" (scroll)="scrollHandler($event)"
            aria-labelledby="navbarDropdown">
            <div class="loadingInSearchBar" *ngIf="isSearchLoading">Loading <i class="fa fa-spinner fa-spin"></i> </div>
            <div class="loadingInSearchBar" *ngIf="!isSearchLoading && productSerachList.length <= 0">No result found...
            </div>
            <div class="row w-100">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                *ngFor="let item of productSerachList|filter:searchProduct;let i=index;let first=first;let last=last">
                <div class="row prodListViewRow">
                  <!-- <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div> -->
                  <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8 listItemNamePadL">
                    <div class="listViewItemName themeColor pl-2" (click)="homeSearch(item)" [tooltip]="item.itemName"
                      placement="top" show-delay="500">
                      {{item.brandName}}-{{item.itemName}}</div>
                    <div class="pl-2">
                      in {{item.categoryName[0]}}
                    </div>
                  </div>
                  <!-- <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 pad0">
                    <div *ngIf="!item.isInventory || (item.isInventory && item.stockInHand>0)">
                      <div *ngIf="item.selectedItem && !item.selectedItem.isAdded" (click)="addToCart(i)"
                        class="whiteClr secGradient listActionBtn prodListActionBtnDiv positionRelative"> Add to cart
                        <div class="addToCartLoader" *ngIf="item.isLoading"><img src="assets/img/spinner.gif"></div>
                      </div>
                      <div *ngIf="!item.selectedItem && !item.isAdded" (click)="addToCartNonItemGroup(i)"
                        class="whiteClr secGradient listActionBtn prodListActionBtnDiv positionRelative">
                        Add to cart
                        <div class="addToCartLoader" *ngIf="item.isLoading"><img src="assets/img/spinner.gif"></div>
                      </div>
                      <div class="cartCount prodListActionBtnDiv"
                        *ngIf="item.selectedItem &&  item.selectedItem.isAdded">
                        <div class="flex alignCenter prodCartCountProdList">
                          <div class="primaryGradient incrementDecrement"
                            (click)="updateCartQuantityProdList(i,'decrease')"><i class="fa fa-angle-left"
                              aria-hidden="true"></i>
                          </div>
                          <div class="itemCount"><b>{{item.selectedItem.quantity}}</b></div>
                          <div class="primaryGradient incrementDecrement"
                            (click)="updateCartQuantityProdList(i,'increase')"><i class="fa fa-angle-right"
                              aria-hidden="true"></i></div>
                        </div>
                      </div>
                      <div class="cartCount prodListActionBtnDiv" *ngIf="!item.selectedItem &&  item.isAdded">
                        <div class="flex alignCenter prodCartCountProdList">
                          <div class="primaryGradient incrementDecrement"
                            (click)="updateCartQuantityItemGroup(i,'decrease')"><i class="fa fa-angle-left"
                              aria-hidden="true"></i>
                          </div>
                          <div class="itemCount"><b>{{item.quantity}}</b></div>
                          <div class="primaryGradient incrementDecrement"
                            (click)="updateCartQuantityItemGroup(i,'increase')"><i class="fa fa-angle-right"
                              aria-hidden="true"></i></div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 store">
        <div class="d-flex">
          <div class="rounded-4"  *ngIf="orgnizationData && orgnizationData.isShowAnotherWebsite" [style.background]="orgnizationData.anotherWebsiteThemeColor">
            <div class="inner-span">
              <a [href]="orgnizationData.anotherWebsiteLink" style="color: white;">
                {{orgnizationData.anotherWebsiteDisplayName}}
              </a>
                
            </div>
            </div>
        <span routerLink="/web/store_location" *ngIf="orgnizationData && orgnizationData.warehouseListCount>1"> <img
            src="assets/img/Visit store.png" alt="Visit Store"> Visit
          Store</span>
        <span *ngIf="user && user.userId" routerLink="/web/ag/profile"> <img src="assets/img/profilec.png"
            alt="My Cart">
          Profile</span>
        <span data-toggle="modal" data-target="#cartModal"> 
          <img src="assets/img/Cart.png" alt="My Cart"> My Cart
          <span class="cartcount" *ngIf="cartList">{{cartList.length}}</span>
          <span class="cartcount" *ngIf="!cartList">0</span>
        </span>
        </div>
      </div>
      
    </div>
  </div>
</header>
<nav class="navbar navbar-expand-md  sticky-top desktopView" *ngIf="orgnizationData">
  <div class="collapse navbar-collapse flex-column " id="navbar" *ngIf="!isLoading && !isServerError">
    <ul class="navbar-nav  w-100  px-3">
      <li class="nav-item  dropdown megamenu-li">
        <a class="nav-link dropdown-toggle dropdownMenu" data-toggle="dropdown"><span class="dropDownMenuName">All
            Categories</span> <span class="sr-only">(current)</span></a>
        <div class="dropdown-menu megamenu categoryDropdown animated fadeIn ">
          <div class="dropdown-menu-inner">
            <div class="row">
              <div class="col-md-3 padR0">
                <ul class="mainCatUL ">
                  <li *ngFor="let category of categoryList;let i=index"
                    class="level1CatName catBrdR catDropBrdBtm dropdownLiColor" (mouseenter)="parentCategoryIndex = i"
                    (mouseenter)="subCategoryIndex = 0" routerLink="/web/ag/products"
                    [queryParams]="{category:category.name,category_uid:category.categoryUid }">{{category.name}}</li>
                  <li class="catBrdR" routerLink="/web/ag/categories">All Categories &nbsp;&nbsp; <b
                      class="fa fa-angle-right" aria-hidden="true"></b>
                  </li>
                </ul>
              </div>
              <div class="col-md-3 padR0 padL0"
                *ngIf="categoryList?.length > 0 && categoryList[parentCategoryIndex].subCatList?.length > 0">
                <ul class="mainCatUL level2Back ">
                  <li class="level2Cat catBrdR catDropBrdBtm">{{categoryList[parentCategoryIndex].name}}</li>
                  <li *ngFor="let category of categoryList[parentCategoryIndex].subCatList;let i=index"
                    (mouseenter)="subCategoryIndex = i" class="level2CatName catBrdR catDropBrdBtm dropdownLiColor"
                    routerLink="/web/ag/products"
                    [queryParams]="{category:categoryList[parentCategoryIndex].name,category_uid:category.categoryUid,sub_category:category.name }">
                    {{category.name}}</li>
                </ul>
              </div>
              <div class="col-md-3 padR0 padL0"
                *ngIf="categoryList?.length > 0 && categoryList[parentCategoryIndex].subCatList?.length > 0 && categoryList[parentCategoryIndex].subCatList[subCategoryIndex].childCatList?.length > 0">
                <ul class="mainCatUL">
                  <li class="level2Cat">{{categoryList[parentCategoryIndex].subCatList[subCategoryIndex].name}}</li>
                  <li
                    *ngFor="let category of categoryList[parentCategoryIndex].subCatList[subCategoryIndex].childCatList;let i=index"
                    class="dropdownLiColor" routerLink="/web/ag/products"
                    [queryParams]="{category:categoryList[parentCategoryIndex].name,category_uid:category.categoryUid,sub_category:categoryList[parentCategoryIndex].subCatList[subCategoryIndex].name,ch_category:category.name }">
                    {{category.name}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li> <span class="bredcrumb">|</span>

      <li class="nav-item dropdown megamenu-li" *ngFor="let menu of menubarList">
        <a class="nav-link"><span class="dropDownMenuName">{{menu.menuName}}</span>&nbsp;<span
            class="bredcrumb">|</span></a>


        <div class="dropdown-menu megamenu animated fadeIn" *ngIf="menu.catList && menu.catList.length >0">
          <div class="dropdown-menu-inner">
            <div class="row">
              <div class="col-md-3" *ngFor="let category of menu.catList[0].subCatList | slice:0:4;">
                <h6 [routerLink]="['/web/ag/products']"
                  [queryParams]="{category:menu.menuName,category_uid:category.categoryUid,sub_category:category.name}">
                  <b>{{category.name}}</b>
                </h6>
                <ul>
                  <li class="prodBrandCatList catDropBrdBtm" *ngFor="let chaildCat of category.childCatList"
                    [routerLink]="['/web/ag/products']"
                    [queryParams]="{category:menu.menuName,category_uid:chaildCat.categoryUid,sub_category:category.name,ch_category:chaildCat.name}">
                    {{chaildCat.name}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>





        <!-- <div class="dropdown-menu megamenu animated fadeIn">
          <div class="dropdown-menu-inner">
            <div class="row">

              <div class="col-md-3" *ngIf="menu.brand">
                <h6><b>{{menu.brand.dataTitle}}</b></h6>
                <ul>
                  <li class="prodBrandCatList catDropBrdBtm" *ngFor="let brand of menu.brandList | slice:0:6;"
                    [routerLink]="['/web/ag/products']"
                    [queryParams]="{brandUid:brand.brandUid,type:'brnd',brandName:brand.brandName}">{{brand.brandName}}
                  </li>
                </ul>
              </div>

              <div class="col-md-3" *ngIf="menu.category">
                <h6><b>{{menu.category.dataTitle}}</b></h6>
                <ul>
                  <li class="prodBrandCatList catDropBrdBtm" *ngFor="let category of menu.catList | slice:0:6;"
                    [routerLink]="['/web/ag/products']"
                    [queryParams]="{categoryUid:category.categoryUid,type:'catg',catName:category.name}">
                    {{category.name}}</li>
                </ul>
              </div>

              <div class="col-md-3" *ngIf="menu.item">
                <h6><b>{{menu.item.dataTitle}}</b></h6>
                <ul>
                  <li class="prodBrandCatList catDropBrdBtm" *ngFor="let prod of menu.itemList | slice:0:6;"
                    (click)="itemDetail(prod)">{{prod.itemName}}</li>
                </ul>
              </div>
              <div class="col-md-6">
                <div class="dropdownBannerImage" *ngFor="let discount of menu.discountList | slice:0:1">
                  <img src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                    this.src='assets/img/errorLogo.jpg'>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </li>
    </ul>
  </div>
</nav>


<!-----SING IN / SING UP FORM-->
<div class="modal fade commonPopUP" id="signInModal" data-backdrop="static" tabindex="-1" role="dialog"
  aria-labelledby="signInModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header secGradient whiteClr ">
        <h5 class="modal-title " id="signInModalLabel"
          *ngIf="signUpInPopup == 'signIn' && fromSignup == false || signUpInPopup =='signInOptions'">Sign In
        </h5>
        <h5 class="modal-title " id="signInModalLabel" *ngIf="signUpInPopup == 'createAccount'">Create Account</h5>
        <h5 class="modal-title " id="signInModalLabel" *ngIf="signUpInPopup == 'singUP' && fromSignup == false">Sign Up
        </h5>
        <h5 class="modal-title " id="signInModalLabel" *ngIf="signUpInPopup == 'signIn' && fromSignup == true">Sign Up
        </h5>
        <button type="button" class="close whiteClr" data-dismiss="modal" aria-label="Close"
          *ngIf="isUserAllInfoAvailable">
          <span aria-hidden="true" class="whiteClr">&times;</span>
        </button>
      </div>
      <div class="popupToaster  animated fadeInDown" *ngIf="isShowActionToast"
        [ngClass]="{'warning':isShowWarning,'success':isShowSuccess,'error':isShowError}">{{actionMessage}}</div>
      <div class="modal-body">

        <!------LOGIN------>
        <div *ngIf="signUpInPopup == 'signIn'">
          <div class="formContainer" *ngIf="loginTab == 'withEmailPassword'">
            <form name="form" #appForm="ngForm" [formGroup]="signInForm" (ngSubmit)="loginWithPassword()">
              <div class="form-group">
                <label for="exampleInputEmail1">Email/Mobile no.<span class="redCrl">*</span></label>
                <input class="form-control" type="email" placeholder=" Mobile / Email ID"
                  [(ngModel)]="formData.username" formControlName="email" required>
                <span class="text-danger"
                  *ngIf="signInForm.controls['email'].hasError('required') && (signInForm.controls['email'].dirty || signInForm.controls['email'].touched)">This
                  field is required</span>
                <span class="text-danger"
                  *ngIf="signInForm.controls['email'].hasError('pattern') && (signInForm.controls['email'].dirty || signInForm.controls['email'].touched)">Please
                  Enter valid email address or phone number</span>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Password<span class="redCrl">*</span></label>
                <div class="positionRelative">
                  <input [type]="pass" class="form-control" id="exampleInputPassword1" placeholder="Password"
                    [(ngModel)]="formData.password" name="password" formControlName="password">
                  <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                    *ngIf="passIcon == 'show'"></i>
                  <i class="fa fa-eye passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                    *ngIf="passIcon == 'hide'"></i>
                </div>
                <span class="text-danger"
                  *ngIf="signInForm.controls['password'].hasError('required') && (signInForm.controls['password'].dirty || signInForm.controls['password'].touched)">Password
                  required</span>
                <div class="forGotPass" (click)="openForgotPassword()">Forgot Password?</div>
              </div>
              <br>
              <div class="tCenter">
                <button type="submit" class="btn btn-primary secGradient whiteClr rectangleBtn appButton submitBtn"
                  [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}">
                  <span class="button__text">LOGIN</span>
                </button>
              </div>
            </form>
          </div>

          <div class="formContainer" *ngIf="loginTab == 'withPassword'">

            <div *ngIf="loginWithOtpTap == 'enterNo' ">
              <form name="form" #appForm="ngForm" [formGroup]="loginWithOtpForm" (ngSubmit)="loginWithOtp(appForm)">
                <div class="form-group">
                  <label for="exampleInputEmail1">Enter Mobile No<span class="redCrl">*</span></label>
                  <input class="form-control" type="text" placeholder="Mobile" [(ngModel)]="resendOtp.username"
                    formControlName="mobile" (keypress)="dataService.appConstant.keyPress($event)" minlength=10
                    maxlength=10 required>
                  <span class="text-danger"
                    *ngIf="loginWithOtpForm.controls['mobile'].hasError('required') && (loginWithOtpForm.controls['mobile'].dirty || loginWithOtpForm.controls['mobile'].touched)">This
                    field is required</span>
                  <span class="text-danger"
                    *ngIf="loginWithOtpForm.controls['mobile'].hasError('pattern') && (loginWithOtpForm.controls['mobile'].dirty || loginWithOtpForm.controls['mobile'].touched)">Please
                    Enter valid phone number</span>
                </div>
                <br>
                <div class="tCenter">
                  <button type="submit" class="btn secGradient btn-primary whiteClr rectangleBtn appButton submitBtn"
                    [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}"><span
                      class="button__text">SUBMIT</span></button>
                </div>
              </form>
            </div>

            <div *ngIf="loginWithOtpTap == 'enterOTP' ">
              <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && loginWithOtpVerify(appForm)">
                <div class="form-group">
                  <label for="exampleInputPassword1">OTP<span class="redCrl">*</span></label>
                  <div class="positionRelative">
                    <input [type]="pass" class="form-control" id="exampleInputPassword1" placeholder="Enter OTP"
                      [(ngModel)]="resendOtp.otp" name="otp" #otp="ngModel" required>
                    <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                      *ngIf="passIcon == 'show'"></i>
                    <i class="fa fa-eye passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                      *ngIf="passIcon == 'hide'"></i>
                  </div>
                  <div class="timeLeft">Time left:{{timeLeft}}</div>
                </div>
                <br>
                <div class="tCenter">
                  <button type="submit" class="btn secGradient whiteClr btn-primary rectangleBtn appButton submitBtn"
                    [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}">
                    <span class="button__text">SUBMIT OTP</span></button>
                  <div class="createAccount">Didn't get OTP? <span class="underLine"
                      (click)="loginWithOtp()">Resend</span></div>
                </div>
              </form>
            </div>
            <div *ngIf="loginWithOtpTap == 'otpUserInfo' && !isUserAllInfoAvailable">
              <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && updateProfile(appForm)">
                <div class="form-group">
                  <label for="exampleInputEmail1">First Name<span class="redCrl">*</span></label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Enter your name" [(ngModel)]="user.firstName" name="otpfirstName"
                    #otpfirstName="ngModel" required>
                  <span class="text-danger"
                    *ngIf="(otpfirstName.touched || appForm.submitted) && otpfirstName.errors?.required">Name is
                    required</span>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Last Name<span class="redCrl">*</span></label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Enter your name" [(ngModel)]="user.lastName" name="lastName" #lastName="ngModel"
                    required>
                  <span class="text-danger"
                    *ngIf="(lastName.touched || appForm.submitted) && lastName.errors?.required">Name is
                    required</span>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Email</label>
                  <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Enter email" [(ngModel)]="user.email" name="optemail" #optemail="ngModel" email>
                  <!-- <span class="text-danger"
                    *ngIf="(optemail.touched || appForm.submitted) && optemail.errors?.required">
                    Email
                    is required</span>
                  <span class="text-danger" *ngIf="optemail.touched && optemail.errors?.optemail">Enter a valid email
                    address</span> -->
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Mobile<span class="redCrl">*</span></label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Enter your mobile no" [(ngModel)]="user.mobile" name="optmobile" #optmobile="ngModel"
                    required (keypress)="dataService.appConstant.keyPress($event)" minlength=10 maxlength=10>
                  <span class="text-danger"
                    *ngIf="(optmobile.touched || appForm.submitted) && optmobile.errors?.required">Mobile no is
                    required</span>
                  <span class="text-danger"
                    *ngIf="(optmobile.touched || appForm.submitted) && optmobile.errors?.minlength">Mobile no must be 10
                    digit</span>
                </div>
                <br>
                <div class="tCenter">
                  <button type="submit" class="btn secGradient whiteClr btn-primary rectangleBtn appButton submitBtn"
                    [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}">
                    <span class="button__text">SAVE</span></button>
                </div>
              </form>
            </div>
          </div>

          <div class="signWithSection" *ngIf="loginTab == 'withEmailPassword'">
            <hr>
            <div class="signWithlabel"> Sign In With</div>
          </div>
          <br>

          <div class="row">
            <div class="col-4">
              <!-- *ngIf="loginTab == 'withPassword'" (click)="openLoginPop('withOtp')" -->
              <!-- <div class="signupwith floatR">
                <div class="themeColor"><i class="fa fa-mobile" aria-hidden="true"></i></div>
                <div class="secondThemeColor signwithLabel">OTP</div>
              </div> -->
            </div>

            <div class="col-4 " *ngIf="loginTab == 'withOtp'" (click)="openLoginPop('withPassword')">
              <div class="signupwith fbContainer fbSection floatR">
                <div class="themeColor"><i class="fa fa-lock fb" aria-hidden="true"></i></div>
                <div class="secondThemeColor signwithLabel signwithLabelFb">Password</div>
              </div>
            </div>
            <div class="col-4" (click)="facebookAuth()" *ngIf="loginTab == 'withEmailPassword'">
              <div class="signupwith fbContainer fbSection">
                <div class="themeColor"><i class="fa fa-facebook fb" aria-hidden="true"></i></div>
                <div class="secondThemeColor signwithLabel signwithLabelFb">facebook</div>
              </div>
            </div>
            <div class="col-4" (click)="googleAuth()" *ngIf="loginTab == 'withEmailPassword'">
              <div class="signupwith fbContainer">
                <div class="themeColor"><i class="fa fa-google fb" aria-hidden="true"></i></div>
                <div class="secondThemeColor signwithLabel signwithLabelFb">Google</div>
              </div>
            </div>
          </div>
          <br>
          <div class="createAccount" (click)="openSignUP('createAccount')">New to
            <span
              *ngIf="orgnizationData && orgnizationData.organizationName">{{orgnizationData.organizationName}}</span>?
            <span class="underLine">Create
              Account</span>
          </div>
        </div>

        <!------CREATE ACCOUNT------>
        <div *ngIf="signUpInPopup == 'createAccount'">
          <br>
          <div class="tCenter">
            <div class="secGradient rectangleBtn createAccBtn" (click)="proccedWithOtp()">
              <div class="createAccountIcon" style="font-size: 23px;">
                <i class="fa fa-mobile" aria-hidden="true"></i>
              </div>
              <div class="createAccLabel">
                &nbsp;&nbsp; &nbsp; Sign up with OTP
              </div>
            </div>

            <!-- <div class="fgBg rectangleBtn createAccBtn" (click)="facebookAuth()">
              <div class="createAccountIcon">
                <i class="fa fa-facebook" aria-hidden="true"></i>
              </div>
              <div class="createAccLabel">
                &nbsp;&nbsp; &nbsp; Sign up with facebook
              </div>
            </div>

            <div class="googleBg rectangleBtn createAccBtn" (click)="googleAuth()">
              <div class="createAccountIcon">
                <i class="fa fa-google" aria-hidden="true"></i>
              </div>
              <div class="createAccLabel">
                &nbsp;&nbsp; &nbsp; Sign up with google
              </div>
            </div> -->

            <div class="signWithSection">
              <hr>
              <div class="signWithlabel" style="left: 47%;">OR</div>
            </div>
            <br>

            <div class="primaryGradient rectangleBtn createAccBtn" (click)="openSignUP('singUP')">
              <div class="createAccountIcon">
                <i class="fa fa-envelope-o" aria-hidden="true"></i>
              </div>
              <div class="createAccLabel">
                &nbsp;&nbsp; &nbsp; Sign up with email
              </div>
            </div>
          </div>

          <div class="trmConditionNote">
            By signing up, you are agree <span
              *ngIf="orgnizationData && orgnizationData.organizationName">{{orgnizationData.organizationName}}</span>
            <span class="cursorPointer" (click)="goToPrivacyPolicy('Terms_and_Conditions')">Terms and conditions</span>
            or <span class="cursorPointer" (click)="goToPrivacyPolicy('Privacy_Policy')">Privacy Policy</span>
            or <span class="cursorPointer" (click)="goToPrivacyPolicy('Return_Policy')">Return Policy</span>
          </div>

          <br>
          <div class="createAccount" (click)="openSignUP('signInOptions')">Member of <span
              *ngIf="orgnizationData && orgnizationData.organizationName">{{orgnizationData.organizationName}}</span>?
            <span class="underLine">Login</span>
          </div>
        </div>

        <div *ngIf="signUpInPopup == 'signInOptions'">
          <br>
          <div class="tCenter">
            <div class="secGradient rectangleBtn createAccBtn" (click)="openSignInWithOtp()">
              <div class="createAccountIcon" style="font-size: 23px;">
                <i class="fa fa-mobile" aria-hidden="true"></i>
              </div>
              <div class="createAccLabel">
                &nbsp;&nbsp; &nbsp; Sign In with OTP
              </div>
            </div>

            <!-- <div class="fgBg rectangleBtn createAccBtn" (click)="facebookAuth()">
              <div class="createAccountIcon">
                <i class="fa fa-facebook" aria-hidden="true"></i>
              </div>
              <div class="createAccLabel">
                &nbsp;&nbsp; &nbsp; Sign up with facebook
              </div>
            </div>

            <div class="googleBg rectangleBtn createAccBtn" (click)="googleAuth()">
              <div class="createAccountIcon">
                <i class="fa fa-google" aria-hidden="true"></i>
              </div>
              <div class="createAccLabel">
                &nbsp;&nbsp; &nbsp; Sign up with google
              </div>
            </div> -->

            <div class="signWithSection">
              <hr>
              <div class="signWithlabel" style="left: 47%;">OR</div>
            </div>
            <br>

            <div class="primaryGradient rectangleBtn createAccBtn" (click)="openSignInWithEmail()">
              <div class="createAccountIcon">
                <i class="fa fa-envelope-o" aria-hidden="true"></i>
              </div>
              <div class="createAccLabel">
                &nbsp;&nbsp; &nbsp; Sign In with email
              </div>
            </div>
          </div>
        </div>

        <!------SIGN UP------>
        <div *ngIf="signUpInPopup == 'singUP'">
          <div class="formContainer">
            <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && signUp()" novalidate>
              <div class="form-group">
                <label for="exampleInputEmail1">Your Name<span class="redCrl">*</span></label>
                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="Enter your name" [(ngModel)]="formData.name" name="firstName" #firstName="ngModel"
                  required>
                <span class="text-danger"
                  *ngIf="(firstName.touched || appForm.submitted) && firstName.errors?.required">Name is required</span>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Email<span class="redCrl">*</span></label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="Enter email" [(ngModel)]="formData.email" name="email" #email="ngModel" required email>
                <span class="text-danger" *ngIf="(email.touched || appForm.submitted) && email.errors?.required"> Email
                  is required</span>
                <span class="text-danger" *ngIf="email.touched && email.errors?.email">Enter a valid email
                  address</span>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Mobile<span class="redCrl">*</span></label>
                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="Enter your mobile no" [(ngModel)]="formData.mobile" name="mobile" #mobile="ngModel"
                  required (keypress)="dataService.appConstant.keyPress($event)" minlength=10 maxlength=10>
                <span class="text-danger"
                  *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">Mobile no is required</span>
                <span class="text-danger"
                  *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.minlength">Mobile no must be 10
                  digit</span>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Password<span class="redCrl">*</span></label>
                <div class="positionRelative">
                  <input [type]="pass" class="form-control" id="exampleInputPassword1" placeholder="Enter password"
                    [(ngModel)]="formData.password" name="password" #password="ngModel" required>
                  <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                    *ngIf="passIcon == 'show'"></i>
                  <i class="fa fa-eye passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                    *ngIf="passIcon == 'hide'"></i>
                </div>
                <span class="text-danger"
                  *ngIf="(password.touched || appForm.submitted) && password.errors?.required">Password is
                  required</span>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Confirm Password <span class="redCrl">*</span> </label>
                <div class="positionRelative">
                  <input [type]="pass1" class="form-control" id="exampleInputPassword1"
                    placeholder="Enter password again" [(ngModel)]="formData.confirmPass" name="Confirm"
                    #Confirm="ngModel" required>
                  <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword1()" aria-hidden="true"
                    *ngIf="passIcon1 == 'show'"></i>
                  <i class="fa fa-eye passHideShow" (click)="hideShowPassword1()" aria-hidden="true"
                    *ngIf="passIcon1 == 'hide'"></i>
                </div>
                <span class="text-danger"
                  *ngIf="formData.confirmPass && formData.password && (formData.confirmPass != formData.password)">
                  Password and confirm password must be same
                </span>
                <span class="text-danger"
                  *ngIf="(Confirm.touched || appForm.submitted) && Confirm.errors?.required">Confirm Password is
                  required</span>
              </div>
              <br>
              <div class="tCenter">
                <button type="submit" class="btn btn-primary secGradient whiteClr rectangleBtn appButton submitBtn"
                  [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}"><span
                    class="button__text">SUBMIT</span></button>
              </div>
            </form>
          </div>
          <br>
          <div class="createAccount" (click)="openSignUP('signIn')">Member of <span
              *ngIf="orgnizationData && orgnizationData.organizationName">{{orgnizationData.organizationName}}</span>?
            <span class="underLine">Login</span></div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-----FORGOT PASSWORD-->
<div class="modal fade commonPopUP" id="forgotPassword" tabindex="-1" role="dialog" aria-labelledby="signInModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header secGradient whiteClr ">
        <h5 class="modal-title " id="signInModalLabel">Forgot password</h5>
        <button type="button" class="close whiteClr" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" class="whiteClr">&times;</span>
        </button>
      </div>

      <div class="popupToaster  animated fadeInDown" *ngIf="isShowActionToast"
        [ngClass]="{'warning':isShowWarning,'success':isShowSuccess,'error':isShowError}">{{actionMessage}}</div>

      <div class="modal-body" *ngIf="tab == 'enterno'">
        <div class="formContainer">
          <form name="form" #appForm="ngForm" [formGroup]="forgotPassForm" (ngSubmit)="sendOTP(appForm)">
            <div class="form-group">
              <label for="exampleInputEmail1">Email<span class="redCrl">*</span></label>
              <input class="form-control" type="email" placeholder="Email ID" [(ngModel)]="resendOtp.username"
                formControlName="email" required>
              <span class="text-danger"
                *ngIf="forgotPassForm.controls['email'].hasError('required') && (forgotPassForm.controls['email'].dirty || forgotPassForm.controls['email'].touched)">This
                field is required</span>
              <span class="text-danger"
                *ngIf="forgotPassForm.controls['email'].hasError('pattern') && (forgotPassForm.controls['email'].dirty || forgotPassForm.controls['email'].touched)">Please
                Enter valid email address or phone number</span>
            </div>
            <br>
            <div class="tCenter">
              <button type="submit" class="btn secGradient btn-primary whiteClr rectangleBtn appButton submitBtn"
                [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}">
                <span class="button__text">SEND OTP</span></button>
            </div>
            <br>
          </form>
        </div>
      </div>

      <div class="modal-body" *ngIf="tab == 'otp'">
        <div class="formContainer">
          <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && verifyOTP(appForm)">
            <div class="form-group" *ngIf="isLoginOtpRequested">
              <label for="exampleInputPassword1">OTP<span class="redCrl">*</span></label>
              <div class="positionRelative">
                <input [type]="pass" class="form-control" id="exampleInputPassword1" placeholder="Enter otp"
                  [(ngModel)]="resendOtp.otp" name="otp" #otp="ngModel" required>
                <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                  *ngIf="passIcon1 == 'show'"></i>
                <i class="fa fa-eye passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                  *ngIf="passIcon1 == 'hide'"></i>
              </div>
              <span class="text-danger" *ngIf="(otp.touched || appForm.submitted) && otp.errors?.required">Please enter
                otp</span>
              <div class="timeLeft">Time left:{{timeLeft}}</div>
            </div>
            <br>
            <div class="tCenter">
              <button type="submit" class="btn secGradient  btn-primary whiteClr rectangleBtn appButton submitBtn"
                [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}">
                <span class="button__text">VERIFY OTP</span></button>
              <div class="createAccount" *ngIf="isLoginOtpRequested" (click)="resendOTP()">Didn't get otp? <span
                  class="underLine">Resend</span></div>
            </div>
            <br>
          </form>
        </div>
      </div>

      <div class="modal-body" *ngIf="tab == 'changePass'">
        <div class="formContainer">
          <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && changePass(appForm)">
            <div class="form-group">
              <label for="exampleInputPassword1">New Password<span class="redCrl">*</span></label>
              <div class="positionRelative">
                <input [type]="pass" class="form-control" id="exampleInputPassword1" placeholder="Enter password"
                  [(ngModel)]="resendOtp.newPassword" name="password" #password="ngModel" required>
                <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                  *ngIf="passIcon == 'show'"></i>
                <i class="fa fa-eye passHideShow" (click)="hideShowPassword()" aria-hidden="true"
                  *ngIf="passIcon == 'hide'"></i>
              </div>
              <span class="text-danger" *ngIf="(password.touched || appForm.submitted) && password.errors?.required">New
                Password is required</span>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Confirm Password <span class="redCrl">*</span> </label>
              <div class="positionRelative">
                <input [type]="pass1" class="form-control" id="exampleInputPassword1" placeholder="Enter password again"
                  [(ngModel)]="resendOtp.confirmPass" name="Confirm" #Confirm="ngModel" required>
                <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword1()" aria-hidden="true"
                  *ngIf="passIcon1 == 'show'"></i>
                <i class="fa fa-eye passHideShow" (click)="hideShowPassword1()" aria-hidden="true"
                  *ngIf="passIcon1 == 'hide'"></i>
              </div>
              <span class="text-danger"
                *ngIf="formData.confirmPass && formData.password && (formData.confirmPass != formData.newPassword)">
                Password and confirm password must be same
              </span>
              <span class="text-danger"
                *ngIf="(Confirm.touched || appForm.submitted) && Confirm.errors?.required">Confirm Password is
                required</span>
            </div>
            <br>
            <div class="tCenter">
              <button type="submit" class="btn secGradient btn-primary whiteClr rectangleBtn appButton submitBtn"
                [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}"><span
                  class="button__text">SUBMIT</span></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- CART MODAL -->
<div class="modal fade" id="cartModal" tabindex="-1" role="dialog" aria-labelledby="cartModalPop" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="closeButton" data-dismiss="modal">
      <i class="fa fa-times" aria-hidden="true"></i>
    </div>
    <div class="modal-content">
      <div class="modal-body">
        <div *ngIf="cartList?.length > 0">
          <div class="itemList" *ngFor="let item of cartList;let i=index">
            <div class="cartItemImage"
            [ngClass]="{'outofstock-img': !item.isStockAvailable}"
             (click)="itemDetail(item)">
              <img class="animated fadeIn" src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                loading="lazy" onError="this.src='assets/img/errorLogo.png'">
            </div>
            <div class="row itemRow">
              <div class="col-md-5">
                <div class="itemName" (click)="itemDetail(item)" [tooltip]="item.displayName" placement="top"
                  show-delay="500">
                  <div>{{item.itemName}}</div>
                  <div>
                    <span style="color: green;font-weight: bold;font-size: 12px;"
                      *ngIf="!item.selectedItem && item.discountName">
                      <span class="fa-stack fa-sm" style="font-size: 9px;">
                        <i class="fa fa-certificate fa-stack-2x"></i>
                        <i class="fa fa-tag fa-stack-1x fa-inverse"></i>
                      </span>{{item.discountName}}</span>
                  </div>
                  <div>
                    <span *ngIf="!item.isBuyXGetYDiscount"
                      class="amount comboAmountF"><b>{{currencySymbol}}{{item.finalPrice}}</b></span>
                    <span *ngIf="item.isBuyXGetYDiscount"
                      class="amount comboAmountF"><b>{{currencySymbol}}{{item.withoutDiscAmount}}</b></span>
                    <span class="mrpprice pl-3" *ngIf="!item.discountName && item.isMrpDiscount == true">MRP</span>
                    <span class="sale-price" *ngIf="!item.discountName && item.isMrpDiscount == true">
                      <span>{{currencySymbol}}</span>
                      <span>{{item.mrp}}</span>
                    </span>
                    <span class="sale-price pl-1"
                      *ngIf="item.discountName && !item.isBuyXGetYDiscount && item.isMrpDiscount == false">
                      <span>{{currencySymbol}}</span>
                      <span>{{item.withoutDiscAmount}}</span>
                    </span>
                  </div>
                  <!-- <div *ngIf="!item.isStockAvailable">
                    <label class="outofstock">Out Of Stock</label>
                  </div> -->
                </div>
              </div>
              <div class="col-md-3">
                <div class="flex contentJustify" *ngIf="item.isStockAvailable">
                  <div class="primaryGradient incrementDecreament" (click)="updateCartQuantity(i,'decrease')"><i
                      class="fa fa-angle-left" aria-hidden="true"></i></div>
                  <div class="itemCount"><b>{{item.quantity}}</b></div>
                  <div class="primaryGradient incrementDecreament" (click)="updateCartQuantity(i,'increase')"><i
                      class="fa fa-angle-right" aria-hidden="true"></i></div>
                </div>
                <div class="colrgreenFreeItem" *ngIf="item.totalFreeQty">
                  {{item.totalFreeQty}} FREE</div>
                <div *ngIf="!item.isStockAvailable">
                  <label class="outofstock">Out Of Stock</label>
                </div>
              </div>
              <div class="col-md-3 tCenter">
                <div><b> {{currencySymbol}} {{item.finalPrice * item.quantity | number : fractionSize}}</b></div>
                <div class="themeColor savedAmount" *ngIf="item.salePrice - (item.finalPrice * item.quantity) > 0">
                  Saved
                  <span *ngIf="item.salePrice - (item.finalPrice * item.quantity) > 0">{{currencySymbol}}
                    {{item.salePrice - (item.finalPrice * item.quantity) | number : fractionSize}}</span>
                  <span *ngIf="item.salePrice - (item.finalPrice * item.quantity) <= 0">{{currencySymbol}} 0</span>
                </div>
              </div>
              <div class="col-md-1" (click)="removeItemFromCart(item)">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="noProductFoundCartPop" *ngIf="cartList?.length <= 0">
          <img src="assets/img/emptyProdList.png">
          <div class="subMsg">No item in your cart.....</div>
        </div>

      </div>
      <div class="modal-footer" *ngIf="cartList?.length > 0">
        <div class="row footerRow">
          <div class="col-md-3">
            <!-- <div class="themeColor savedAmount">You Saved</div> -->
            <div class="itemTotal"><b>TOTAL</b></div>
          </div>
          <div class="col-md-3">
            <!-- <div class="themeColor savedAmount">{{currencySymbol}} {{cartObject.savedAmount}}</div> -->
            <!-- <div class="themeColor savedAmount">{{currencySymbol}} 0</div> -->
            <div class="itemTotal"><b>{{currencySymbol}} {{cartObject.totalAmount | number:'1.2-2' }}</b></div>
          </div>
          <div class="col-md-6">
            <button type="button" class="btn secGradient whiteClr btn-primary rectangleBtn appButton checkoutBTn"
              (click)="goToCheckout()" [disabled]="checkbtnDis">View Cart & Checkout &nbsp;&nbsp; <i
                class="fa fa-angle-right" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section id="mobileSideBarSection">
  <div class="modal left fade" id="mobileSideBar" tabindex="-1" role="dialog" aria-labelledby="mobileSideBarList"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="closeSidebar" data-dismiss="modal">
        <i class="icofont-close-squared"></i>
      </div>
      <div class="modal-content">
        <div class="modal-header primaryGradient">
          <div width="100%" height="auto" class="orgName" *ngIf="orgnizationData && orgnizationData.organizationName">
            {{orgnizationData.organizationName}}
          </div>
          <!-- <img src="assets/img/Logo.png" class="sideBarLogo" alt="Logo"> -->
        </div>
        <div class="modal-body">

          <div class="row mSideBarSignRow">
            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 tCenter pad0" data-dismiss="modal"
              *ngIf="!user  || !user.userId" (click)="openSignInModal('signInOptions')">
              <div class="secGradient rectangleBtn mrgAuto">SIGN IN</div>
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 tCenter pad0" data-dismiss="modal"
              *ngIf="!user || !user.userId" (click)="openSignInModal('createAccount')">
              <div class="secGradient rectangleBtn mrgAuto">SIGN UP</div>
            </div>
          </div>

          <div class="mSideBarShopByCat" routerLink="/web/ag/categories" data-dismiss="modal">SHOP BY CATEGORY</div>

          <ul>
            <li routerLink="" data-dismiss="modal" (click)="homeReload()"> <i class="icofont-home"></i>
              <span>Home</span>
            </li>
          </ul>
          <hr>
          <ul>
            <li routerLink="/web/ag/profile" data-dismiss="modal"> <i class="icofont-ui-user"></i> <span>My
                Profile</span></li>
            <li (click)="goToProfile('my-Address')" data-dismiss="modal"> <i class="icofont-location-pin"></i> <span>My
                Address</span></li>

            <li (click)="goToProfile('my-Orders')" data-dismiss="modal"> <i class="icofont-list account-list-icons"></i>
              <span>My
                Orders</span>
            </li>
            <li (click)="goToProfile('ChangePassword')" data-dismiss="modal"> <i class="icofont-ui-user"></i>
              <span>Change Password</span>
            </li>
            <!-- <li *ngIf="showButton" (click)="addToHomeScreen()"> <i class="icofont-download"></i>
              <span>Download App</span>
            </li> -->
            <!-- <li (click)="goToProfile('my-Wishlist')"> <i class="icofont-grocery"></i> <span>My Wishlist</span></li>
            <li (click)="goToProfile('my-Wallet')"> <i class="icofont-wallet"></i> <span>My Wallet</span></li>
            <li (click)="goToProfile('my-Rewards')"> <i class="icofont-coins"></i> <span>My Rewards</span></li> -->
          </ul>
          <hr>
          <ul>
            <li routerLink="/web/store_location" data-dismiss="modal"> <i class="fa fa-circle" aria-hidden="true"></i>
              <span>Store location</span>
            </li>
            <li *ngIf="footerData.isShowAboutUs" (click)="goToPrivacyPolicy('About_Us')" data-dismiss="modal"><i class="fa fa-circle"
              aria-hidden="true"></i> <span>About Us</span></li>
            <li *ngIf="footerData.isShowPrivacyPolicy" (click)="goToPrivacyPolicy('Privacy_Policy')" data-dismiss="modal"><i class="fa fa-circle"
                aria-hidden="true"></i> <span>Privacy policy</span></li>
            <li *ngIf="footerData.isShowTermsConditions" (click)="goToPrivacyPolicy('Terms_and_Conditions')" data-dismiss="modal"> <i class="fa fa-circle"
                aria-hidden="true"></i> <span>Team and condition</span></li>
                <li *ngIf="footerData.isShowReturnPolicy" (click)="goToPrivacyPolicy('Return_Policy')" data-dismiss="modal"> <i class="fa fa-circle"
                  aria-hidden="true"></i> <span>​Refund Policy</span></li>
                
                <div>
                  <a *ngIf="footerData && footerData.playStoreLink" href="{{footerData.playStoreLink}}">
                      <img class="playstore-icon" src="assets/img/GooglePlay.png" alt="Logo">
                  </a>
                  <a *ngIf="footerData && footerData.appStoreLink" href="{{footerData.appStoreLink}}">
                      <img class="playstore-icon" src="assets/img/AppStore.png" alt="Logo">
                  </a>
              </div>
              <div>
                <a href="https://apnagrahak.com/" target="_blank">
                    <img class="poweredby" src="assets/img/Powered By AG3.png" alt="Logo">
                </a>
            </div>

          </ul>
        </div>
        <div class="modal-footer" (click)="signOut()" *ngIf="user && user.userId" data-dismiss="modal">
          <div class="primaryGradient rectangleBtn mrgAuto">SIGN OUT</div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- warehouse popup -->
<section>
  <div class="modal fade commonPopUP" id="storeModel" data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="signInModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header secGradient whiteClr ">
          <h5 class="modal-title " id="signInModalLabel">Select a store nearest to your delivery location.</h5>
        </div>
        <div class="modal-body">
          <div class="formContainer">
            <div id="storeCards">
              <div class="row storeScroll">
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6" *ngFor="let store of storesList;let i=index">
                  <div class="wrapper" (click)="selectWarehouse(store)"
                    [ngClass]="{'activeStore':warehouseData.warehouseId == store.warehouseId}">
                    <div for="option-1" class="storeLabel"
                      [ngClass]="{'activeStore':warehouseData.warehouseId == store.warehouseId}">
                      <span>{{store.wareHouseName}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- sign out -->
<div class="modal fade" id="singOutMessageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <p class="msgtitle">Are you sure you want to sign out?</p>
        <p></p>
        <div class="btns">
          <button data-dismiss="modal" aria-label="Close" type="button"
            class="btn btn-secondary  whiteClr rectangleBtn appButton submitBtn"><span
              class="button__text">No</span></button>&nbsp;
          <button (click)="signOutConfim()" type="submit"
            class="btn btn-primary secGradient whiteClr rectangleBtn appButton submitBtn" [disabled]="isButtonDisabled"
            [ngClass]="{'button--loading':isButtonDisabled}"><span class="button__text">Yes</span></button>
        </div>
      </div>
    </div>
  </div>
</div>