import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
// import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { CommanservicesService } from '../services/commanservices.service';
import { TokenStorageService } from '../services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AppConstantsService {
  presentToast: any;



  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    // public translate: TranslateService,
    public TokenStorageService: TokenStorageService,
    public cookie: CookieService
  ) { }

  public version = 1.0;
  public TENANT_NAME: string = environment.tenantName;
  public HOME_URL: string = environment.serverUrl;
  public MASTER_API: string = environment.serverUrl2;
  public IMAGE_URL: string = environment.ImageUrl;
  public IMAGE_BASE_URL: string = environment.ImgBaseUrl;
  public GET_FILE: string = environment.ImageUrl;
  public PINCODE_API: string = environment.PincodeUrl;
  public paymentUrl: string = environment.paymentUrl;
  public SUCCESS: number = 200;
  public EXPIRECARD: number = 1914;
  public UNAUTHORIZED: number = 401;
  public FORBIDDEN: number = 403;
  public NOT_FOUND: number = 404;
  public TOKEN_EXPIRED: number = 412;
  public INTERNAL_SERVER_ERROR: number = 500;
  public REQUIRED_FIELD_EMPTY: number = 601;
  public UNIQUE_KEY_EXISTS: number = 602;
  public PASSWORD_MISMATCHED: number = 603;
  public USER_NOT_FOUND: number = 604;
  public USER_NOT_ACTIVE: number = 607;
  public USER_IS_LOCKED: number = 608;
  public EMAIL_ALREADY_EXISTS = 602;
  public PREVIOUS_PASSWORD = 603;

  // public imgUrl: string = environment.ImageUrl;


  // public translateMessage(key, callback) {
  //   return this.translate.get('messages.' + key).subscribe((res: string) => {
  //     callback(res);
  //   });
  // }

  // public translateText(key, callback) {
  //   return this.translate.get(key).subscribe((res: string) => {
  //     callback(res);
  //   });
  // }

  public SERVER_URLS: any = {

    SIGN_UP: "auth/ecomm-sign-up",
    SING_IN: "auth/authenticate",
    SOCIAL_LOGIN: "auth/login-with-social",
    CHANGE_PASS: 'auth/change-password',
    VERIFY_OTP: 'auth/verify-otp',
    RESEND_OTP: 'auth/resend-otp',
    FORGOT_PASSWORD: 'auth/forgot-password',
    LOGIN_WITH_OTP: "auth/authenticate-by-otp",
    SEND_TOP: "auth/request-for-otp",
    SIGN_IN_SIGN_UP_BY_OTP: "auth/sign-up-by-otp",
    CHANGE_PASSWORD: "user-management/users/change-password-using-old-pswd",
    BULK_ADD_TO_CART: "cart-management/add-wishlist-to-cart/",
    UPDATE_PROFILE: "user-management/update-profile",
    'APP_LIST_ITEM': 'config-management/app-list-items',

    GET_ORGANIZATION: "organization/get-primary-organization",

    GET_CATEGORIES: "ecomm/categories",
    GET_LOYALTYPOINTS: "loyalty-management/search-customer",
    ECOMM_PAGE_LIST: 'ecomm-page/ecomm-page-list',
    GET_PAGE_PREVIEW_LIST: "ecomm-page/get-page-preview/",
    GET_SEQ_OF_PAGE: "ecomm-page/get-page-seqno",
    GET_PAGE_BY_SEQ: "ecomm-page/get-seq-page",

    GET_ITEMS: "ecomm/ecomm-all-item-list",
    GET_SINGLE_ITEM: "ecomm/ecomm-single-item-list",
    GET_VIEW_ALL_ITEMS: "ecomm/website-item-list",
    // GET_GROUP_WISE_ITEM: "ecomm/ecomm-category-item-list",
    GET_GROUP_WISE_ITEM: "ecomm/ecomm-single-item-list",
    FREQUENTLY_BOUGHT: "ecomm/frequently-bought-item",
    COMBO_ITEM_LIST: "ecomm/ecomm-combo-items-list",
    ITEM_WISHLIST_ORDER: "cart-management/add-wishlist-to-cart/",
     GET_FOOTER_DATA :"auth/get-cms-footer-data",

    'MENU_BAR_LIST': 'ecomm/get-menubar/',
    // privacy policy
    'PRIVACY_POLICY': 'about_us_contact_us_management/privacy-policy',
    'TERMS_AND_CONDITION': 'about_us_contact_us_management/terms-conditions',
    'Return_Policy': 'about_us_contact_us_management/return-policy',
    'ABOUT_US': 'about_us_contact_us_management/get-about-us-details',

    
    'ITEM_DETAILS_API': 'ecomm/ecomm-items-details',
    ITEM_DETAIL_BY_NAME: "ecomm/ecomm-items-details-by-link",

    //Review 
    'ADD_REVIEWS': 'rating-management/add-item-rating',
    'REVIEW_LIST': 'rating-management/list-item-rating-website',
    'GET_REVIEW_LIST': 'rating-management/rating-details/',
    'REVIEW_LIKE_DISLIKE': 'rating-management/add-like-status',

    // Faq Apis
    'ADD_QUESTIONS': 'faq-management//add-faq-question',
    'GET_QUSTION_LIST': 'faq-management//list-faq-by-item-website',
    'ADD_ANSWER': 'faq-management//add-faq-answer',
    'LIKE_DISLIKE': 'faq-management/add-like-answer-status',
    'FAQ_LIST_FOR_ALL': 'faq-management/list',

    //ADD TO CART
    ADD_TO_CART: "cart-management/add-to-cart",
    // CART_LIST: "cart-management/list-items/",
    CART_LIST: "cart-management/cart-list-items",
    EDIT_CART: "cart-management/",

    //ADDRESS
    ADD_ADDRESS: "ecomm-user/add-address",
    LIST_ADDRESS: "ecomm-user/user-address-list/",
    EDIT_ADDRESS: "ecomm-user/edit-address",


    //CHECKOUT
    'PAYMENT_MODE_LIST': 'payment-mode/ecomm-payment-mode-list',
    'SAVE_CARD_DETAILS_LIST': 'orderb2c/get-customer-card-details/',
    'ADD_ORDER_API': 'orderb2c/add-ecomm-order',
    'UPDATE_REZORPAY': 'orderb2c/update-razorpay-payment-detail',
    'GET_PAYMENT_MODE_LIST': 'payment-mode/ecomm-payment-mode-list',
    'GET_MASTER_GET_WAY_LIST': 'mastergatway/list',
    'GET_CHECKOUT_DETAILS': 'ecomm-order/order-checkout',
    "GET_ABANDONED_ID": "ecomm-order/abandoned-order-checkout",
    'NEW_APPLY_COUPON_DISCOUNT_API': "ecomm-order/apply-coupon-order-checkout",


    //ORDER

    GET_USER_ORDERS: "ecomm-order/order-details-by-user",
    'GENERATE_INVOICE': 'ecomm-order/get-invoice/',
    'CANCEL_ORDER': 'ecomm-order/cancel-order',



    // coupon api
    // Active Coupon List
    'GET_ACTIVE_COUPON_DISCOUNT': 'ecomm/get-all-active-coupon-for-ecomm',
    // coupon valid
    'VALID_COUPON': 'ecomm-order/validate-coupon',
    'GET_CARD_DETAILS': 'order/get-customer-card-details/',

    // Active Discount
    'ACTIVE_DISCOUNT': 'ecomm/get-all-active-discount-for-ecomm',
    'CATEGORY_BRAND_ACTIVE_DISCOUNT': 'ecomm/ecomm-discount-brands-categories',

    // get order discount list
    'GET_ACTIVE_ENTAIRE_ORDER_DISCOUNT_DISCOUNT': 'ecomm-order/get-valid-discount-for-ecomm-on-entire-order',

    'VALID_ORDER_DISCOUNT': 'ecomm-order/apply-discount',


    // get recently
    'GET_RECENTLY_VIEW_LIST': 'recentlyviewed/list',
    'ADD_RECENTLY_VIEW_LIST': 'recentlyviewed/add-recently-viewed',

    GET_ALL_CITY: "city/get-all-cities",

    //FILTER
    FILTER_BRAND_LIST: "ecomm/category-wise-brand-list",
    FILTER_DISCOUNT_LIST: "ecomm/discount-filter-list",

    BRAND_LIST: "ecomm/get-brand-list",
    GET_RELATED_PRODUCT: "ecomm/get-related-items-category",

    // return Api
    'GET_ORDER_INVOICE': 'ecomm-order/order-invoice/',
    'GET_RETURN_INVOICE': 'ecomm-order/get-invoice-by-id/',
    'GET_RETURN_AMOUNT': 'tax-ind/return-calculate',
    'RETURN_ITEM': 'ecomm-order/add-ecomm-sales-return',
    'ORDERLIST': 'ecomm-order/order-details-by-user',

    GET_WAREHOUSE: "warehouse/all-warehouse-list",
    DELETE_USER_ADDRESS: "ecomm-user/delete-user-address/",

    CANCLE_NEWS_LETTER: "notification/inactive-setting-by-user-link",

    "ALL_LIST_PAGE_COUNT": "ecomm/ecomm-single-item-total",


    // ORGNIZATION NEW API
    "ORGNIZATION_NEW_API": "organization/get-organization-domain",

    "GET_ITEM_LIST_WITHOUT_PRICE_LIST": "ecomm/ecomm-single-list-wo-pricelist-discount",
    "GET_ADDRESS_BY_PINCODE": "pincode/",

    "PERFERRED_WAREHOUSE": "warehouse/add-preferred-store/",

    // HOME SEARCH
    SEARCH_ITEM_FROM_HOME: "ecomm-website/all-item-search-list/",

    "GET_NOTIFICATION_SETTINGS": "notification/get-user-setting-list-detials/",
    "CHANGE_NOTIFICATION_SETTING_BY_ID": "notification/change-user-notification-setting-status/",
    "CHANGE_ALL_NOTIFICATION_SETTINGS": "notification/change-all-user-notification-setting-status/",

    // interceptor component APIs
    'GEN_TOKEN': 'auth/generate-token',

    "GET_RELATED_ITEM_LIST_IN_CART_PAGE": "item-management/get-related-item-list"

  };

  public UI_URLS: any = {
    'LOGIN': '/web/login',
    'HOME': '/web/home',
  };

  /**
     * Success Message Method
     */
  public successMsg(statusCode: any) {
    // this.translateMessage(statusCode, (message) => {
    let x = document.getElementById("success-snackbar");
    x.innerHTML = '<span class="successmsg">' + statusCode + '</span>';
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

    // });
  }



  public notification(payload: any) {
    var x = document.getElementById("notification-snackbar");
    document.getElementById('notification-snackbar').style.animation = 'bounceInDown faster'
    x.innerHTML = '<div><span class="imgsuccess"><img src=' + payload.notification.image + ' onError="this.src=\'assets/img/favicon.png\'"></span></div>&nbsp;&nbsp;' + '<span class="successhead">' + payload.notification.title +
      '</span>' + '<br>' + '<span class="successmsg">' + payload.notification.body + '</span>';
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
  }




  /**
   * Error Message Method
   */
  public errorMsg(statusCode: any) {
    // this.translateMessage(statusCode, (message) => {
    var x = document.getElementById("error-snackbar");
    x.innerHTML = '<span class="successmsg">' + statusCode + '</span>';
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
    // });
  }



  /**
   * Error Message Method
   */
  public warningMsg(statusCode: any) {
    // this.translateMessage(statusCode, (message) => {
    var x = document.getElementById("error-snackbar");
    x.innerHTML = '<span class="successmsg">' + statusCode + '</span>';
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
    // });
  }

  /** 
   * Get token from session storage and generate authorization header
  */

  accessToken: any;

  public authHeader() {
    this.accessToken = this.TokenStorageService.getToken()
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('userInfo')) {
        var user = JSON.parse(localStorage.getItem('userInfo'));
        if (this.accessToken) {
          return btoa(this.accessToken + ":" + user.userUniqueId + ":" + user.deviceUniqueId);
        }
      } else {
        return "";
      }
    }
  }


  // public getAccessToken(data: any) {
  //   this.accessToken = data;
  //   this.authHeader();
  // }


  getCurrencySymbol() {
    // let currencySimbol: any;
    // let sessionData: any;
    // if (localStorage.getItem('primary_organization')) {
    //   sessionData = JSON.parse(localStorage.getItem('primary_organization'));
    //   if (sessionData) {
    //     if (sessionData.currency) {
    //       if (sessionData.currency.currencyCode == 'INR') {
    //         currencySimbol = '₹';
    //       }
    //     }
    //   }
    //   return currencySimbol;
    // }

    return '₹';
  }

  public getOrganisation() {
    var organizationId: string;
    if (localStorage.getItem('primary_organization')) {
      var user = JSON.parse(localStorage.getItem('primary_organization'));
      if (user) {
        return organizationId = user.organizationProfileId;
      }
    }
  }



  getConstantList(type: string) {
    let list: any[] = [];
    let constantList = [];
    if (localStorage.getItem('commonAppList')) {
      constantList = JSON.parse(localStorage.getItem('commonAppList'));
    }
    constantList.forEach((element: any) => {
      if (element.listItemType == type) {
        list.push(element);
      }
    });
    list.sort((a, b) => 0 - (a > b ? -1 : 1));
    return list;
  }


  //ACCEPT ONLY NUMBER
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

}
