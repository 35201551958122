import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { CommanservicesService } from './commanservices.service';
import { TokenStorageService } from './token-storage.service';
declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class FirebaseServiceService {

  formData: any = {};
  public apiResponse: any = {}
  data: any;
  public isInstanceLogin: boolean = false;
  tenantName: any;
  constructor(
    public dataService: CommanservicesService,
    public tokenStorage: TokenStorageService,
    @Inject('reliablemart') private reliablemart: AngularFireAuth,
    @Inject('akashmart') private akashmart: AngularFireAuth
  ) { }

  public getSomethingFromProject1(): void {
    const ref = `yourdocpath`;
    // this.firebaseAuthProject1.auth()
    // this.firebaseProject1.collection(ref, query => query).valueChanges().subscribe(docs => {
    //   //do something with docs
    // });
  }

  public getSomethingFromProject2(): void {
    const ref = `yourdocpath`;
    // this.firebaseProject2.collection(ref, query => query).valueChanges().subscribe(docs => {
    //   //do something with docs
    // });
  }

  // public getTenantName(tenantName: any) {
  //   this.tenantName = tenantName;
  // }

  authLogin(provider: any, tenantName) {
    let url: any
    if (tenantName == 'akashmart') {
      url = this.akashmart;
    } else if(tenantName == 'reliablemart'){
      console.log(tenantName);
      url = this.reliablemart;
    }
    this.callAuthMethod(provider, url);
  }

  callAuthMethod(provider, url) {
    return url.auth.signInWithPopup(provider).then(response => {
      this.apiResponse = response.user;
      var name = this.apiResponse.displayName.split(" ");
      this.formData.firstName = name[0];
      this.formData.lastName = name[1];
      this.formData.profilePic = this.apiResponse.photoURL;
      this.formData.email = this.apiResponse.email;
      this.formData.loginWith = 1;
      this.formData.eautoCustomerLogin = false;
      this.formData.platform = "Website";
      this.formData.socialPlatfrom = 'google'
      // this.formData.uuid = this.makeid();
      this.dataService.LoginRequest(this.formData, this.dataService.appConstant.SERVER_URLS["SOCIAL_LOGIN"]).subscribe((response) => {
        this.data = response;
        if (this.data.status == 200) {
          this.formData = {};
          this.dataService.appConstant.successMsg(this.data.message);
          if (this.data.result) {
            this.tokenStorage.saveUser(this.data.result);
            this.tokenStorage.saveToken(this.data.result.accessToken);
            this.tokenStorage.saveRefreshToken(this.data.result.refreshToken);
            delete this.data.result.accessToken;
          }
          $('#signInModal').modal('hide');
          this.dataService.sendMessage("LoggedIn");
          this.dataService.getCartList();
          if (this.isInstanceLogin) {
            this.isInstanceLogin = false;
            this.dataService.sendMessage(sessionStorage.getItem('pageName'))
          }
          window.location.reload();
        } else {
        }
      },
        (err) => {
        }
      );
    });
  }

}
