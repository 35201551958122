import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { PolicyData } from "src/app/component/appModels/policy.model";
import { AppConstantsService } from "src/app/constants/app-constants.service";
import { CommanservicesService } from "src/app/services/commanservices.service";
import { getPolicy } from "../actions/policy.action";
import { map, tap } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
// State Model
export class PolicyStateModel {
    policy: any;
    policyLoaded: boolean
}


@State<PolicyStateModel>({
    name: 'policy',
    defaults: {
        policy: {},
        policyLoaded: false
    }
})


@Injectable()
export class PolicyState {
    data: any;
    dummyObj: any = {};
    public URL: any;
    public tab: any;
    constructor(private appConstants: AppConstantsService,
        public dataService: CommanservicesService,
        public route: ActivatedRoute,
        public common: CommanservicesService,
        public constant: AppConstantsService) {
        this.route.params.subscribe(params => {
            this.tab = params.tab
            if (this.tab == 'Privacy_Policy') {
                this.URL = "PRIVACY_POLICY";
            } else   if (this.tab == 'Terms_and_Conditions'){
                this.URL = "TERMS_AND_CONDITION";
            }else   if (this.tab == 'Return_Policy'){
                this.URL = "Return_Policy";
            }else   if (this.tab == 'About_Us'){
                this.URL = "ABOUT_US";
            }
        });

        this.dataService.getMessage().subscribe((msg) => {
            if (msg == 'privacyPolicy') {
                this.URL = "PRIVACY_POLICY";
            }

            if (msg == 'tearmCondition') {
                this.URL = "TERMS_AND_CONDITION";
            }
            if (msg == 'returnPolicy') {
                this.URL = "Return_Policy";
            }

            if (msg == 'About_Us') {
                this.URL = "ABOUT_US";
            }
        
        })
    }

    // selecter has logic to select data
    @Selector()
    static getPolicyData(state: PolicyStateModel) {
        return state.policy
    }

    // get loaded brand
    @Selector()
    static getPolicyLoaded(state: PolicyStateModel) {
        return state.policyLoaded;
    }

    @Action(getPolicy)
    getPolicy({ getState, setState }: StateContext<PolicyStateModel>) {
        return this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS[this.URL]).pipe(tap(result => {
            this.data = result;
            const state = getState();
            setState({
                ...state,
                policy: this.data.result,
                policyLoaded: true
            });
        }));
    }
}