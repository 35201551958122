import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstantsService } from '../constants/app-constants.service';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', tenant_name: '' })
};
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    authApi: any;
    constructor(private http: HttpClient, public appConstant: AppConstantsService) {
        let url = 'auth/generate-token'
        this.authApi = this.appConstant.HOME_URL + url;
    }
    // login, register
    refreshToken(token: string) {
        console.log(token);
        let user = JSON.parse(localStorage.getItem("userInfo") || "{}");
        if (user) {
            return this.http.post(this.authApi, {
                refreshToken: token,
                deviceUniqueId: user.deviceUniqueId
            }, httpOptions);
        }
    }
}