import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import 'firebase/auth';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { HeaderComponent } from './component/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './component/footer/footer.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HomeComponent } from './component/home/home.component';
// import { TranslationService } from './translation/translation';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { OwlNativeDateTimeModule, OwlDateTimeModule } from "ng-pick-datetime";

import { ProductComponent } from './component/product/product.component';
import { MobileFooterComponent } from './component/mobile-footer/mobile-footer.component';
import { AppConstantsService } from './constants/app-constants.service';
import { CommanservicesService } from './services/commanservices.service';
import { CartService } from './services/cart.service';

import { SkeletonLoaderModule } from './component/skeleton-loader/skeleton-loader.module';
import { UnderMantananceComponent } from './component/under-mantanance/under-mantanance.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { Ng5SliderModule } from 'ng5-slider';
import { from } from 'rxjs';
import { WindowRef } from 'src/app/services/windowRef.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxPaginationModule } from 'ngx-pagination';


import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { PrivacyPolicyComponent } from './component/privacy-policy/privacy-policy.component';
import { StoreLocationComponent } from './component/store-location/store-location.component';
import { UnsubscribeNewsletterComponent } from './component/unsubscribe-newsletter/unsubscribe-newsletter.component';

// NGXS
import { NgxsModule } from '@ngxs/store';
import { PolicyState } from './store/state/policy.state';
import { CategorySate } from './store/state/category.state';
import { HomePageState } from './store/state/homepage.state';
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { DatePipe, HashLocationStrategy } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './services/auth.service';
import { TokenStorageService } from './services/token-storage.service';
import { initializeApp } from "firebase/app";
import { PLATFORM_ID, NgZone, LOCALE_ID } from '@angular/core';
import {
  AngularFireAuthProject2,
  AngularFireAuthProject1
} from './firebase.factory';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
const href = window.location.href;
let app={}
if(href.includes("akashmart")){
  app=environment.akashmart;
}else if(href.includes("reliablemartstore")){
  app=environment.reliablemart;
}else {
  app=environment.akashmart;
}



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ProductComponent,
    MobileFooterComponent,
    UnderMantananceComponent,
    PrivacyPolicyComponent,
    StoreLocationComponent,
    UnsubscribeNewsletterComponent,

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    CarouselModule,
    HttpClientModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    // NGXS
    NgxsModule.forRoot([PolicyState, CategorySate, HomePageState]),
    // Ng2SearchPipeModule,
    OwlNativeDateTimeModule,
    OwlDateTimeModule,
    // InfiniteScrollModule,
    FormsModule,
    ReactiveFormsModule,
    SkeletonLoaderModule,
    Ng5SliderModule,
    BrowserAnimationsModule,
    TooltipModule,
    // NgxSliderModule,
    AngularFireModule.initializeApp(app),
    AngularFireAnalyticsModule,
    AngularFireModule,
    AngularFireAuthModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserModule,
  
  ],
  providers: [
    // TranslationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: 'env',
      useValue: environment
    },
    {
      provide: 'akashmart',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFireAuthProject1
    },
    {
      provide: 'reliablemart',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFireAuthProject2
    },
    AppConstantsService,
    CommanservicesService,
    WindowRef,
    AuthService,
    TokenStorageService,
    CookieService,
    DatePipe,
    // HashLocationStrategy

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
